import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  details: [],
};

const teamOwnerSlice = createSlice({
  name: "teamowner",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setDetails(state, actions) {
      state.details = actions.payload;
    },
  },
});

export const { setList, setDetails } = teamOwnerSlice.actions;

export default teamOwnerSlice.reducer;
