import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { getBase64, IsEmpty } from "../../utils/formValidation/formValidation";
import { errorToast } from "../../utils/notificationAlert/notificationAlert";
import DeleteIcon from "@mui/icons-material/Delete";

import { useSelector } from "react-redux";
import { deleteItem } from "../../utils/notificationAlert/swalAlert";

import {
  createPrivacyPolicyBannerRequest,
  deletePrivacyPolicyBannerRequest,
  getAllPrivacyPolicyBannerRequest,
} from "../../APIRequest/bannersHomeAndPrivacyPolicy/bannersHomeAndPrivacyPolicyApi";

const PrivacyPolicyImg = () => {
  let mode = useSelector((state) => state.settings.mode);
  let [privacyPolicyBannerList, setPrivacyPolicyBannerList] = useState([]);
  let [id, setId] = useState("0");
  let [showImgView, setShowImgView] = useState("none");
  let imgView,
    imgRef = useRef();

  const convertImgToBase64 = () => {
    let img = imgRef.files[0];

    getBase64(img).then((base64) => {
      setShowImgView("block");
      imgView.src = base64;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let images = imgRef.files[0];

    if (id == 0 || (id == undefined && images === undefined)) {
      errorToast("Images is required");
    } else if (images !== undefined && !images.type.includes("image")) {
      errorToast("Only support images");
    } else {
      let result = await createPrivacyPolicyBannerRequest(images);

      if (result) {
        window.location.reload();
      }
    }
  };

  const deleteImg = async (id) => {
    let confirm = await deleteItem();
    if (confirm.isConfirmed) {
      let deleteImgResult = await deletePrivacyPolicyBannerRequest(id);
      if (deleteImgResult) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setId(params.get("id"));
    (async () => {
      let data = await getAllPrivacyPolicyBannerRequest();
      setPrivacyPolicyBannerList(data);
    })();
  }, []);
  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Add Privacy Policy Image{" "}
          <Typography variant="caption">
            ( image must be 1680px x 310px)
          </Typography>
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          {/* for add   img  */}
          <Box sx={{ display: showImgView, pb: 2 }}>
            <img
              style={{ width: "300px", height: "200px" }}
              src=""
              alt=""
              ref={(input) => (imgView = input)}
            />
          </Box>
          {/* for edit   img  */}

          <Box
            sx={{
              display: privacyPolicyBannerList?.length > 0 ? "block" : "none",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 3 }}>
              {privacyPolicyBannerList?.map((item, index) => (
                <Box sx={{ display: "flex" }} key={index}>
                  <Box>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={item?.img?.slice(-1)?.[0]?.secure_url}
                      alt=""
                    />
                  </Box>
                  <Box>
                    <DeleteIcon
                      onClick={() => deleteImg(item._id)}
                      style={{
                        color: mode === "dark" ? "#512DA8" : "#fff",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box component="form" onSubmit={handleSubmit} accept="image/*">
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="file"
                variant="outlined"
                type="file"
                name="images"
                onChange={convertImgToBase64}
                inputRef={(input) => (imgRef = input)}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              {id !== null && id !== "0" ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PrivacyPolicyImg;
