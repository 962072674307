import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const mainSliderSlice = createSlice({
  name: "mainslider",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
  },
});

export const { setList } = mainSliderSlice.actions;

export default mainSliderSlice.reducer;
