import React from "react";
import { Suspense } from "react";
import LazyLoader from "../../../components/common/loader/lazyLoader";
const AddContact = React.lazy(() =>
  import("../../../components/privacyPolicy/contactUs/addContact")
);

const AddContactUsPage = () => {
  return (
    <>
      <Suspense fallback={<LazyLoader />}>
        <AddContact />
      </Suspense>
    </>
  );
};

export default AddContactUsPage;
