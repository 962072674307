import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import Paper from "@mui/material/Paper";
import { Avatar, Button, Typography } from "@mui/material";
import { getBase64, IsEmpty } from "../../utils/formValidation/formValidation";
import { errorToast } from "../../utils/notificationAlert/notificationAlert";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  createCategoryRequest,
  deleteCategoryImg,
  detailsCategoryRequest,
  updateCategory,
} from "../../APIRequest/categoryApi";
import { useSelector } from "react-redux";
import { deleteItem } from "../../utils/notificationAlert/swalAlert";
import { useNavigate } from "react-router-dom";
import store from "../../redux/store";
import { resetDetails } from "../../redux/features/category/categorySlice";

const AddCategory = () => {
  const navigate = useNavigate();
  let mode = useSelector((state) => state.settings.mode);
  let categoryDetails = useSelector((state) => state.categoryList.details);
  let [id, setId] = useState("0");
  let [showImgView, setShowImgView] = useState("none");
  let imgView,
    imgRef,
    categoryRef = useRef();

  const convertImgToBase64 = () => {
    let img = imgRef.files[0];

    getBase64(img).then((base64) => {
      setShowImgView("block");
      imgView.src = base64;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let category = categoryRef.value;
    let images = imgRef.files[0];

    if (id == 0 || (id == undefined && images === undefined)) {
      errorToast("Images is required");
    } else if (images !== undefined && !images.type.includes("image")) {
      errorToast("Only support images");
    } else if (!IsEmpty(category)) {
      errorToast("Category name is required");
    } else {
      if (id !== null && id !== "0") {
        let result = await updateCategory(id, images, category);
        if (result) {
          navigate("/category/list-category");
          store.dispatch(resetDetails());
        }
      } else {
        let result = await createCategoryRequest(images, category);
        if (result) {
          navigate("/category/list-category");
        }
      }
    }
  };

  const deleteImg = async (public_id) => {
    let confirm = await deleteItem();
    if (confirm.isConfirmed) {
      let deleteImgResult = await deleteCategoryImg(id, public_id);
      if (deleteImgResult) {
        await detailsCategoryRequest(id);
      }
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setId(params.get("id"));
    if (id !== null && id !== "0") {
      (async () => {
        await detailsCategoryRequest(id);
      })();
    }
    // add category field reset when user click update category but not update
    return () => {
      store.dispatch(resetDetails());
    };
  }, [id]);
  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {id !== null && id !== "0" ? "Update Category" : "Add Category"}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* for add category img  */}
          <Box sx={{ display: showImgView, pb: 2 }}>
            <img
              style={{ width: "80px", height: "80px" }}
              src=""
              alt=""
              ref={(input) => (imgView = input)}
            />
          </Box>
          {/* for edit category img  */}
          <Box
            sx={{
              display: categoryDetails[0]?.img.length > 0 ? "block" : "none",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 3 }}>
              {categoryDetails[0]?.img?.map((item, index) => (
                <Box sx={{ display: "flex" }} key={index}>
                  <Avatar
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                    src={item.secure_url}
                    alt=""
                  />
                  <DeleteIcon
                    onClick={() => deleteImg(item.public_id)}
                    style={{
                      color: mode === "dark" ? "#512DA8" : "#fff",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box component="form" onSubmit={handleSubmit} accept="image/*">
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="file"
                variant="outlined"
                type="file"
                name="images"
                onChange={convertImgToBase64}
                inputRef={(input) => (imgRef = input)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                inputRef={(input) => (categoryRef = input)}
                fullWidth
                id="category"
                label="Category Name"
                type="text"
                key={Math.random()}
                defaultValue={
                  categoryDetails.length > 0 ? categoryDetails[0].name : ""
                }
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              {id !== null && id !== "0" ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddCategory;
