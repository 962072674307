import axios from "axios";
import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";

import {
  getToken,
  sessionDestroy,
} from "../../utils/sessionHelper/sessionHelper";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let createShippingCostAndOtherCostRequest = async (data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-shipping-cost`;
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      successToast("Shipping Cost create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllShippingCostAndOtherCostRequest = async (
  pageNo,
  perPage,
  searchKeyword
) => {
  console.log(pageNo, perPage, searchKeyword);
  try {
    store.dispatch(showLoader());
    let URL =
      baseUrl + `/list-shipping-cost/${pageNo}/${perPage}/${searchKeyword}`;
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data[0]?.rows?.length > 0) {
      return res.data?.data[0];
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let detailsShippingCostAndOtherCostRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/get-shipping-cost-by-id/${id}`;
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.length > 0) {
      return res.data?.data;
    } else {
      errorToast("Data not found!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let updateShippingCostAndOtherCostRequest = async (id, data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/update-shipping-cost/${id}`;
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (res.data.status === "success" && res.data?.data?.modifiedCount == 1) {
      successToast("Shipping cost udpate success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteShippingCostAndOtherCostRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-shipping-cost/" + id;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data?.data?.deletedCount > 0) {
      successToast(" delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
