import React from "react";
import ListProduct from "../../components/product/listProduct";

const ListProductPage = () => {
  return (
    <div>
      <ListProduct />
    </div>
  );
};

export default ListProductPage;
