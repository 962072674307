import React, { useEffect } from "react";
// import parse from "html-react-parser";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import NativeSelect from "@mui/material/NativeSelect";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { deleteItem } from "../../utils/notificationAlert/swalAlert";
import { deleteListItem } from "../../redux/features/product/productSlice";
import store from "../../redux/store";
import { useWindowSize } from "../../utils/windowSize/useWindowSize";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import exportFromJSON from "export-from-json";
import {
  deleteProductRequest,
  listProductRequest,
} from "../../APIRequest/productApi";
import Chip from "@mui/material/Chip";
import { InputLabel, MenuItem, Select } from "@mui/material";

const columns = [
  { id: "#no", label: "#No", minWidth: 2, align: "center" },
  { id: "img", label: "Image", minWidth: 10, align: "center" },
  { id: "name", label: "Name", minWidth: 200 },
  { id: "sort-description", label: "Short description", minWidth: 250 },
  { id: "sold", label: "Sold", minWidth: 2 },
  { id: "price", label: "Price", minWidth: 2 },
  { id: "discount", label: "Discount", minWidth: 2 },
  { id: "final-price", label: "Final price", minWidth: 100 },
  { id: "save-amount", label: "Save Amount", minWidth: 120 },
  { id: "quantity", label: "Quantity", minWidth: 2 },
  { id: "category", label: "Categories", minWidth: 2 },
  { id: "sub-category", label: "Sub-categories", minWidth: 140 },
  { id: "brand", label: "Brands", minWidth: 2 },
  { id: "weight", label: "Weight", minWidth: 2 },
  { id: "size", label: "Size", minWidth: 2, align: "center" },
  { id: "color", label: "Color", minWidth: 2 },
  { id: "ratings", label: "Ratings", minWidth: 2 },

  {
    id: "createdAt",
    label: "Created Date",
    minWidth: 150,
    align: "inherit",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
  },
];

const ListProduct = () => {
  const mode = useSelector((state) => state.settings.mode);
  const list = useSelector((state) => state.productList.list);
  const total = useSelector((state) => state.productList.total);
  const [pageNo, setPageNo] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);
  const [searchKeyword, setSearchKeyword] = React.useState("0");
  const [searchTermValue, setSearchTermValue] = React.useState("0");

  const [inStockFilter, setInStockFilter] = React.useState("");

  const handleChangePage = (e, newPage) => {
    setPageNo(newPage);
  };

  const handleChangePerPage = (e) => {
    setPerPage(+e.target.value);
    setPageNo(1);
  };
  const handleChangeInStockProducts = (e) => {
    setInStockFilter(e.target.value);
  };

  const searchTerm = (e) => {
    if (e.target.value.length === 0) {
      setSearchKeyword("0");
    } else {
      setSearchTermValue(e.target.value);
    }
  };

  const handleSearch = () => {
    setPageNo(1);
    setPerPage(5);
    setSearchKeyword(searchTermValue);
  };

  const TextSearch = (e) => {
    let rows = document.querySelectorAll("tbody tr");
    rows.forEach((row) => {
      row.style.display = row.innerText
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
        ? ""
        : "none";
    });
  };

  const deleteProduct = async (id) => {
    let result = await deleteItem();
    if (result.isConfirmed) {
      let deleteResult = await deleteProductRequest(id);
      if (deleteResult) {
        store.dispatch(deleteListItem(id));
      }
    }
  };

  useEffect(() => {
    listProductRequest(pageNo, perPage, searchKeyword, inStockFilter);
  }, [pageNo, perPage, searchKeyword, inStockFilter]);

  let downloadJsonFile = () => {
    const data = [];
    list.map((item) => {
      let listItem = {
        Product_name: item?.name,
        Description: item?.description,
        Sold: item?.sold,
        Price: item?.price,
        Category: item?.category[0]?.name,
        Sub_category: item?.subCategory[0]?.name,
        Brand: item?.brand[0]?.name,
        Quantity: item?.quantity,
        Weight: item?.weight,
        Size: item?.size?.map((i) => i),
        Color: item?.color,
        Discount: item?.discount,
        Final_price: item?.finalPrice,
        Save_amount: item?.saveAmount,
        Date: moment(item["createdAt"]).format("MMMM Do YYYY"),
      };
      data.push(listItem);
    });
    const fileName = "download";
    const exportType = exportFromJSON.types.json;

    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <Paper
      sx={{
        width: useWindowSize().width <= 600 ? "87vw" : "100%",
        overflowX: "auto",
        py: 1,
        px: 3,
      }}
    >
      <Box sx={{ display: useWindowSize().width <= 992 ? "" : "none" }}>
        <Box>
          <Typography
            sx={{ py: 2 }}
            variant={useWindowSize().width < 600 ? "h6" : "h5"}
          >
            Products
          </Typography>
          <Box sx={{ width: 120 }} size="small">
            <FormControl fullWidth>
              <InputLabel
                id="In-Stock-Products"
                sx={{ position: "absolute", top: -4 }}
              >
                Low Stock
              </InputLabel>
              <Select
                sx={{
                  height: useWindowSize().width <= 600 ? 35 : 46,
                }}
                labelId="In-Stock-Products"
                id="In-Stock-Products"
                value={inStockFilter}
                label="Low Stock"
                onChange={handleChangeInStockProducts}
              >
                <MenuItem value={"true"}>In Stock</MenuItem>
                <MenuItem value={"false"}>Out of Stock</MenuItem>
                <MenuItem value={"5"}>Lowest Products {"<="} 5 </MenuItem>
                <MenuItem value={"10"}>Lowest Products {"<="} 10 </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Box sx={{ display: useWindowSize().width <= 992 ? "none" : "" }}>
          <Typography
            sx={{ pt: 2 }}
            variant={useWindowSize().width < 600 ? "h6" : "h5"}
          >
            Products
          </Typography>
          <button onClick={() => downloadJsonFile()}>Download</button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: 120,
              display: useWindowSize().width <= 992 ? "none" : "block",
            }}
            size="small"
          >
            <FormControl fullWidth>
              <InputLabel
                id="In-Stock-Products"
                sx={{ position: "absolute", top: -4 }}
              >
                Low Stock
              </InputLabel>
              <Select
                sx={{
                  height: useWindowSize().width <= 600 ? 35 : 46,
                }}
                labelId="In-Stock-Products"
                id="In-Stock-Products"
                value={inStockFilter}
                label="Low Stock"
                onChange={handleChangeInStockProducts}
              >
                <MenuItem value={"true"}>In Stock</MenuItem>
                <MenuItem value={"false"}>Out of Stock</MenuItem>
                <MenuItem value={"5"}>Lowest Products {"<="} 5 </MenuItem>
                <MenuItem value={"10"}>Lowest Products {"<="} 10 </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Paper
            sx={{
              my: 2,
              border: "1px solid #c3c3c3",
              height: useWindowSize().width <= 600 ? 35 : 46,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: useWindowSize().width <= 500 ? 100 : 200,
              boxShadow: "inherit",
            }}
          >
            <InputBase
              onChange={TextSearch}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Text Search"
              inputProps={{ "aria-label": "search google maps" }}
            />
          </Paper>
          <Paper
            sx={{
              my: 2,
              justifyContent: "end",
              border: "1px solid #c3c3c3",
              height: useWindowSize().width <= 600 ? 35 : "",
              display: "flex",
              alignItems: "center",
              width: useWindowSize().width <= 500 ? 150 : 250,
              boxShadow: "inherit",
            }}
          >
            <InputBase
              onChange={searchTerm}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Deep Search"
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton
              type="button"
              onClick={handleSearch}
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
      </Box>

      <TableContainer
        sx={{
          maxHeight: 420,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderBottom: "1px solid #ccc" }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {row?.img?.slice(0, 10).map((item, index) => (
                        <img
                          key={index}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                          src={item?.secure_url}
                          alt=""
                        />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.name?.length > 20
                      ? row?.name.substr(0, 20) + "..."
                      : row?.name}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.sortDescription?.length > 30
                      ? row?.sortDescription?.substr(0, 30) + "..."
                      : row?.shortDescription}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.sold}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.price}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.discount}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.finalPrice}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.saveAmount}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.quantity}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.category[0]?.name}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.subCategory[0]?.name}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.brand[0]?.name}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.weight}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {row?.size?.map((s, index) => (
                        <Chip key={index} label={s} size="small" />
                      ))}
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.color}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {row?.totalRating}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid #ccc" }}
                    align="inherit"
                  >
                    {moment(row.createdAt).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell
                    align="inherit"
                    sx={{ borderBottom: "1px solid #ccc" }}
                  >
                    <Link to={`/products/add-products?id=${row._id}`}>
                      <BorderColorIcon
                        sx={{
                          cursor: "pointer",
                          color: mode === "dark" ? "#512DA8" : "#fff",
                        }}
                      />
                    </Link>{" "}
                    <DeleteIcon
                      onClick={() => deleteProduct(row._id)}
                      sx={{
                        cursor: "pointer",
                        color: mode === "dark" ? "#512DA8" : "#fff",
                      }}
                      color="secondary"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: useWindowSize().width <= 600 ? "block" : "flex",
          justifyContent: "flex-end",
          py: 4,
        }}
      >
        {/* per Page setub */}
        <Box
          sx={{
            minWidth: 160,
            pr: 3,
            pb: useWindowSize().width <= 600 ? 4 : "",
          }}
        >
          <FormControl>
            <NativeSelect defaultValue={20} onChange={handleChangePerPage}>
              <option value={20}>20 Per Page</option>
              <option value={50}>50 Per Page</option>
              <option value={100}>100 Per Page</option>
            </NativeSelect>
          </FormControl>
        </Box>
        {/* pagination */}
        <Pagination
          count={Math.ceil(total / perPage)}
          onChange={handleChangePage}
          variant="outlined"
          sx={{ color: "#512DA8" }}
        />
      </Box>
    </Paper>
  );
};

export default ListProduct;
