import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  total: 0,
  details: [],
  dropdownCategoryList: [],
  formValue: {
    categoryId: "",
  },
};

const subCategorySlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setTotal(state, actions) {
      state.total = actions.payload;
    },
    deleteListItem(state, actions) {
      state.list = state.list.filter((item) => item._id !== actions.payload);
    },
    setDetails(state, actions) {
      state.details = actions.payload;

      var matchingDetails = state.dropdownCategoryList?.find((obj) =>
        obj?.subCategoryId?.some((item) => item === state.details[0]?._id)
      );
      state.formValue.categoryId = matchingDetails?._id;
    },
    resetDetails(state) {
      state.details = [];
    },
    setDropdownCategoryList(state, actions) {
      state.dropdownCategoryList = actions.payload;
      // state.formValue.categoryId = state.dropdownCategoryList.map((item) => {
      //   return item.subCategoryId === state.details._id ? item._Id : null;
      // });
    },
    setFormValue(state, actions) {
      state.formValue = {
        ...state.formValue,
        [actions.payload.Name]: actions.payload.Value,
      };
    },
  },
});

export const {
  setList,
  setTotal,
  deleteListItem,
  setDetails,
  resetDetails,
  setDropdownCategoryList,
  setFormValue,
} = subCategorySlice.actions;

export default subCategorySlice.reducer;
