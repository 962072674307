import axiosInstance from "../utils/axios/axios";
import axios from "axios";
import baseUrl from "../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../utils/notificationAlert/notificationAlert";
import store from "../redux/store";
import {
  hideLoader,
  showLoader,
} from "../redux/features/settings/setttingsSlice";
import { getToken } from "../utils/sessionHelper/sessionHelper";

import {
  setList,
  setTotal,
  setDetails,
  setDropdownBrandList,
  setDropdownCategoryList,
  setDropdownSubCategoryList,
  setFormValue,
} from "../redux/features/product/productSlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let createProductRequest = async (formData) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/create-product";
    let res = await axios.post(URL, formData, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data.status === "success" && res.data.fileResult.length > 0) {
      successToast("Product create success");
      return true;
    } else if (res.data.data.keyPattern.slug === 1) {
      errorToast(`"${formData.get("name")}" already exits`);
      return false;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let listProductRequest = async (
  pageNo,
  perPage,
  searchKeyword,
  inStockFilter
) => {
  try {
    store.dispatch(showLoader());
    let URL;
    if (inStockFilter.length > 0) {
      URL = `/list-product/${pageNo}/${perPage}/${searchKeyword}?inStock=${inStockFilter}`;
    } else {
      URL = `/list-product/${pageNo}/${perPage}/${searchKeyword}`;
    }

    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res?.data?.status === "success") {
      if (res.data.data?.rows?.length > 0) {
        store.dispatch(setList(res.data.data?.rows));
        store.dispatch(setTotal(res.data?.data?.total));
        return true;
      } else {
        errorToast("Data not found!");
        store.dispatch(setList([]));
        store.dispatch(setTotal([]));
        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let deleteProductRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = `/delete-product/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data.data.deletedCount === 1) {
      successToast("Product delete success");
      return true;
    } else if (res.data.status === "associate") {
      errorToast(res.data.data);
      return false;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let detailsProductRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = `/product-details/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data.status === "success") {
      if (res.data.data.length > 0) {
        let formValue = res.data.data[0];
        store.dispatch(setDetails(res.data.data));
        store.dispatch(
          setFormValue({ Name: "productName", Value: formValue?.name })
        );
        store.dispatch(
          setFormValue({ Name: "remark", Value: formValue?.remark })
        );
        store.dispatch(
          setFormValue({
            Name: "shortDescription",
            Value: formValue?.shortDescription,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "description",
            Value: formValue?.description,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "category",
            Value: formValue?.categoryId,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "subCategory",
            Value: formValue?.subCategoryId,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "brand",
            Value: formValue?.brandId,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "weight",
            Value: formValue?.weight,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "madeIn",
            Value: formValue?.madeIn,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "skinType",
            Value: formValue?.skinType,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "country",
            Value: formValue?.country,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "sku",
            Value: formValue?.sku,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "size",
            Value: formValue?.size,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "color",
            Value: formValue?.color,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "price",
            Value: formValue?.price,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "discount",
            Value: formValue?.discount,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "finalPrice",
            Value: formValue?.finalPrice,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "saveAmount",
            Value: formValue?.saveAmount,
          })
        );
        store.dispatch(
          setFormValue({
            Name: "quantity",
            Value: formValue?.quantity,
          })
        );

        return true;
      } else {
        errorToast("Data not found");
        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let deleteProductImg = async (id, public_id) => {
  let reqBody = { imgId: public_id };
  try {
    store.dispatch(showLoader());
    let URL = `/delete-product-img/${id}`;
    let res = await axiosInstance.post(URL, reqBody);
    store.dispatch(hideLoader());
    if (res.data.status === "success" && res.data.fileResult.result === "ok") {
      successToast("Image delete success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let updateProduct = async (id, formData) => {
  try {
    store.dispatch(showLoader());
    let URL = `${baseUrl}/update-product/${id}`;
    let res = await axios.post(URL, formData, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res?.data?.status === "success" &&
      (res?.data?.fileResult?.modifiedCount === 1 ||
        res?.data?.infoResult?.modifiedCount === 1)
    ) {
      successToast("Product update success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};

// dropdown category list
export let dropdownCategoryList = async () => {
  try {
    // store.dispatch(showLoader());
    let URL = "/dropdown-category/1/1000/0";
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      store.dispatch(setDropdownCategoryList(res?.data?.data[0].rows));
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (error) {
    errorToast("Something went wrong");
    store.dispatch(hideLoader());
    return false;
  }
};

// dropdown sub-category list
export let dropdownSubCategoryList = async () => {
  try {
    // store.dispatch(showLoader());
    let URL = "/dropdown-subcategory";
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data.status === "success") {
      store.dispatch(setDropdownSubCategoryList(res.data.data));
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (error) {
    errorToast("Something went wrong");
    store.dispatch(hideLoader());
    return false;
  }
};

// dropdown brand list
export let dropdownBrandList = async () => {
  try {
    // store.dispatch(showLoader());
    let URL = "/dropdown-brand";
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data.status === "success") {
      store.dispatch(setDropdownBrandList(res.data.data));
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (error) {
    errorToast("Something went wrong");
    store.dispatch(hideLoader());
    return false;
  }
};
