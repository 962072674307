import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";

export const MyFormControl = styled(FormControl)(() => ({
  "& label.Mui-focused": {
    color: "#9E9E9E",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#212121",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#212121",
    },
    "&:hover fieldset": {
      borderColor: "#212121",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#212121",
    },
  },
}));
