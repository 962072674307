import axios from "axios";
import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";

import {
  getToken,
  sessionDestroy,
} from "../../utils/sessionHelper/sessionHelper";
import { setList } from "../../redux/features/mainSlider/mainSliderSlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let getAllMainSliderRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-main-slider/1/100/0";
    let res = await axios.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      store.dispatch(setList(res.data?.data?.[0]?.rows));
      return true;
    } else {
      errorToast("Data not found!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
export let deleteMainSliderRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-main-slider/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());
    if (res.data?.data?.deletedCount > 0) {
      successToast("Slider delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let createMainSliderRequest = async (images) => {
  let postBody = {
    images,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/add-main-slider";
    let res = await axios.post(URL, images, AxiosHeader);
    store.dispatch(hideLoader());

    if (res.data.status === "success" && res.data?.fileResult?.length > 0) {
      successToast("Slider upload success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
