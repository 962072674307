import PageLayout from "../../../pages/home/PageLayout";
import HomePage from "../../../pages/home/HomePage";
import AddCategoryPage from "../../../pages/category/addCategoryPage";
import ListCategoryPage from "../../../pages/category/listCategoryPage";
import AddBrandPage from "../../../pages/brand/addBrandPage";
import ListBrandPage from "../../../pages/brand/listBrandPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import DiscountIcon from "@mui/icons-material/Discount";
import CategoryIcon from "@mui/icons-material/Category";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import ListSubCategoryPage from "../../../pages/subCategory/listSubCategoryPage";
import AddSubCategoryPage from "../../../pages/subCategory/addSubCategoryPage";
import AddProductPage from "../../../pages/product/addProductPage";
import ListProductPage from "../../../pages/product/listProductPage";
import AddCouponPage from "../../../pages/coupon/addCouponPage";
import RefundCashbackPage from "../../../pages/refundCashback/refundCashback";
import ListCouponPage from "../../../pages/coupon/listCouponPage";
import SalesSumamryReportPage from "../../../pages/report/salesReportPage";
import CancelledReportPage from "../../../pages/report/cancelledReportPage";
import OrderReportPage from "../../../pages/report/orderReportPage";
import RunningOrderReportPage from "../../../pages/report/runningOrderReportPage";
import ListCustomerPage from "../../../pages/customer/listCustomerPage";
import UpdateCustomerPage from "../../../pages/customer/updateCustomerPage";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UpdateAdminPage from "../../../pages/admin/updateAdminPage";
import ListAdminPage from "../../../pages/admin/listAdminPage";
import AllOrderPage from "../../../pages/order/all-order";
import OrderDtailsAndUpdatePage from "../../../pages/order/orderDtailsAndUpdate";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import MainSlider from "../../settings/mainSlider";
import BestSalesBanner from "../../settings/bestSalesBanner";
import ProvisionalBazarBanner from "../../settings/provisionalBazarBanner";
import BestOfElectronics from "../../settings/bestOfElectronicesBanner";
import ShippingAndOtherCost from "../../settings/shippingAndOtherCost";
import SocialLink from "../../settings/socialLink";
import ListSocialLink from "../../settings/listSocialLink";
import AddFaqPage from "../../../pages/privacyPolicy/faq/addFaqPage";
import ListFaqPage from "../../../pages/privacyPolicy/faq/listFaqPage";
import AddProductPrivacyPolicyPage from "../../../pages/privacyPolicy/productPrivacyPolicy/addProductPrivacyPage";
import ListProductPrivacyPolicyPage from "../../../pages/privacyPolicy/productPrivacyPolicy/listProductPrivayPage";
import ListPrivacyPolicyPage from "../../../pages/privacyPolicy/privacyPolicy/listPrivacyPolicyPage";
import AddPrivacyPolicyPage from "../../../pages/privacyPolicy/privacyPolicy/addPrivacyPolicyPage";
import AddAboutUsPage from "../../../pages/privacyPolicy/aboutUs/addAboutUsPage";
import ListAboutUsPage from "../../../pages/privacyPolicy/aboutUs/listAboutUsPage";
import ListTermsPage from "../../../pages/privacyPolicy/terms/listTermsPage";
import AddTermsPage from "../../../pages/privacyPolicy/terms/addTermsPage";
import TermsImg from "../../settings/termsImg";
import PrivacyPolicyImg from "../../settings/privacyPolicyImg";
import AboutUsImg from "../../settings/aboutUsImg";
import FaqImg from "../../settings/faqImg";
import DealerBrandLogo from "../../settings/dealerBrandLogo";
import ListTeamPage from "../../../pages/privacyPolicy/team/listTeamPage";
import AddTeamPage from "../../../pages/privacyPolicy/team/addTeamPage";
import AddContactUsPage from "../../../pages/privacyPolicy/contactUs/addContactUsPage";
import ListContactUsPage from "../../../pages/privacyPolicy/contactUs/listContactUsPage";
import ContactUsImg from "../../settings/contactUsImg";
import TeamBannerImg from "../../settings/teamBannerImg";
import TeamOwnersImg from "../../settings/teamOwnersImg";
import ReturnedReportPage from "../../../pages/report/returnedReportPage";
import ListShippingAndOtherCost from "../../settings/listShippingAndOtherCost";

const appRoutes = [
  // dashboard
  {
    path: "/",
    element: <HomePage />,
    state: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardIcon />,
    },
  },

  // Order
  {
    path: "/order",
    element: <PageLayout />,
    state: "order",
    sidebarProps: {
      displayText: "Order",
      icon: <ShoppingBagIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "order.index",
      },
      {
        path: "/order/all-order",
        element: <AllOrderPage />,
        state: "order.all-order",
        sidebarProps: {
          displayText: "Orders",
        },
      },
      {
        path: "/order/details-and-update-order",
        element: <OrderDtailsAndUpdatePage />,
        state: "order.details-and-update-order",
        // sidebarProps: {
        //   displayText: "Orders",
        // },
      },
    ],
  },

  // category
  {
    path: "/category",
    element: <PageLayout />,
    state: "category",
    sidebarProps: {
      displayText: "Category",
      icon: <CategoryIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "category.index",
      },
      {
        path: "/category/add-category",
        element: <AddCategoryPage />,
        state: "category.add-category",
        sidebarProps: {
          displayText: "Add Category",
        },
      },
      {
        path: "/category/list-category",
        element: <ListCategoryPage />,
        state: "category.list-category",
        sidebarProps: {
          displayText: "List Category",
        },
      },
    ],
  },

  // sub category
  {
    path: "/subCategory",
    element: <PageLayout />,
    state: "SubCategory",
    sidebarProps: {
      displayText: "Sub-category",
      icon: <AccountTreeIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "SubCategory.index",
      },
      {
        path: "/subCategory/add-subcategory",
        element: <AddSubCategoryPage />,
        state: "SubCategory.add-subCategory",
        sidebarProps: {
          displayText: "Add Sub-category",
        },
      },
      {
        path: "/subCategory/list-subCategory",
        element: <ListSubCategoryPage />,
        state: "SubCategory.list-subCategory",
        sidebarProps: {
          displayText: "List Sub-category",
        },
      },
    ],
  },

  // brand
  {
    path: "/brand",
    element: <PageLayout />,
    state: "brand",
    sidebarProps: {
      displayText: "Brand",
      icon: <BrandingWatermarkIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "brand.index",
      },
      {
        path: "/brand/add-brand",
        element: <AddBrandPage />,
        state: "brand.add-brand",
        sidebarProps: {
          displayText: "Add brand",
        },
      },
      {
        path: "/brand/list-brand",
        element: <ListBrandPage />,
        state: "brand.list-brand",
        sidebarProps: {
          displayText: "List brand",
        },
      },
    ],
  },

  // products
  {
    path: "/products",
    element: <PageLayout />,
    state: "Products",
    sidebarProps: {
      displayText: "Products",
      icon: <HomeRepairServiceIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "Products.index",
      },
      {
        path: "/products/add-products",
        element: <AddProductPage />,
        state: "Products.add-product",
        sidebarProps: {
          displayText: "Add Product",
        },
      },
      {
        path: "/products/list-products",
        element: <ListProductPage />,
        state: "Products.list-product",
        sidebarProps: {
          displayText: "List Product",
        },
      },
    ],
  },

  // coupon
  {
    path: "/coupon",
    element: <PageLayout />,
    state: "coupon",
    sidebarProps: {
      displayText: "Coupon",
      icon: <DiscountIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "coupon.index",
      },
      {
        path: "/coupon/add-coupon",
        element: <AddCouponPage />,
        state: "coupon.add-coupon",
        sidebarProps: {
          displayText: "Add Coupon",
        },
      },
      {
        path: "/coupon/list-coupon",
        element: <ListCouponPage />,
        state: "coupon.list-coupon",
        sidebarProps: {
          displayText: "List Coupon",
        },
      },
    ],
  },

  // Refund/cashback
  {
    path: "/refund-cashback",
    element: <PageLayout />,
    state: "refund-cashback",
    sidebarProps: {
      displayText: "Refund/CB",
      icon: <CurrencyExchangeIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "refund-cashback.index",
      },
      {
        path: "/refund-cashback/refund-or-cashback",
        element: <RefundCashbackPage />,
        state: "refund-cashback.refund-or-cashback",
        sidebarProps: {
          displayText: "Bkash",
        },
      },
    ],
  },

  // customer
  {
    path: "/customer",
    element: <PageLayout />,
    state: "Customer",
    sidebarProps: {
      displayText: "Customers",
      icon: <PeopleOutlineIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "customer.index",
      },
      {
        path: "/customer/update-customer",
        element: <UpdateCustomerPage />,
        state: "customer.update-customer",
        // sidebarProps: {
        //   displayText: "Update Customer",
        // },
      },
      {
        path: "/customer/list-customer",
        element: <ListCustomerPage />,
        state: "customer.list-customer",
        sidebarProps: {
          displayText: "Customer List",
        },
      },
    ],
  },

  // admin
  {
    path: "/admins",
    element: <PageLayout />,
    state: "Admin",
    sidebarProps: {
      displayText: "Admins",
      icon: <AdminPanelSettingsIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "admins.index",
      },
      {
        path: "/admins/update-admin",
        element: <UpdateAdminPage />,
        state: "admins.update-admin",
        // sidebarProps: {
        //   displayText: "Update Customer",
        // },
      },
      {
        path: "/admins/list-admin",
        element: <ListAdminPage />,
        state: "admins.list-admin",
        sidebarProps: {
          displayText: "Admin List",
        },
      },
    ],
  },

  // report
  {
    path: "/report",
    element: <PageLayout />,
    state: "report",
    sidebarProps: {
      displayText: "Report",
      icon: <StackedBarChartIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "report.index",
      },
      {
        path: "/report/sales-report",
        element: <SalesSumamryReportPage />,
        state: "report.sales-report",
        sidebarProps: {
          displayText: "Sales Report",
        },
      },
      {
        path: "/report/cancelled-report",
        element: <CancelledReportPage />,
        state: "report.cancelled-report",
        sidebarProps: {
          displayText: "Cancelled Report",
        },
      },
      {
        path: "/report/returned-report",
        element: <ReturnedReportPage />,
        state: "report.returned-report",
        sidebarProps: {
          displayText: "Retured Report",
        },
      },
      {
        path: "/report/order-report",
        element: <OrderReportPage />,
        state: "report.order-report",
        sidebarProps: {
          displayText: "TotalOrder Report",
        },
      },
      {
        path: "/report/running-order-report",
        element: <RunningOrderReportPage />,
        state: "report.running-order-report",
        sidebarProps: {
          displayText: "Running Order Report",
        },
      },
    ],
  },

  // settings
  {
    path: "/settings",
    element: <PageLayout />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsApplicationsIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "settings.index",
      },

      {
        path: "/settings/main-slider",
        element: <MainSlider />,
        state: "settings.main-slider",
        sidebarProps: {
          displayText: "Main Slider",
        },
      },
      {
        path: "/settings/best-sales-banner",
        element: <BestSalesBanner />,
        state: "settings.best-sales-banner",
        sidebarProps: {
          displayText: "Best Sales Banner",
        },
      },
      {
        path: "/settings/provisional-bazar-banner",
        element: <ProvisionalBazarBanner />,
        state: "settings.provisional-bazar-banner",
        sidebarProps: {
          displayText: "Provisional Bazar Banner",
        },
      },
      {
        path: "/settings/best-of-electronices-banner",
        element: <BestOfElectronics />,
        state: "settings.best-of-electronices-banner",
        sidebarProps: {
          displayText: "Best Of Electronics Banner",
        },
      },
      {
        path: "/settings/add-shipping-and-other-cost",
        element: <ShippingAndOtherCost />,
        state: "settings.add-shipping-and-other-cost",
        sidebarProps: {
          displayText: "Add Shipping & Other Cost",
        },
      },
      {
        path: "/settings/list-shipping-and-other-cost",
        element: <ListShippingAndOtherCost />,
        state: "settings.list-shipping-and-other-cost",
        sidebarProps: {
          displayText: "List Shipping & Other Cost",
        },
      },
      {
        path: "/settings/add-social-link",
        element: <SocialLink />,
        state: "settings.add-social-link",
        sidebarProps: {
          displayText: "Add Social Link",
        },
      },
      {
        path: "/settings/list-social-link",
        element: <ListSocialLink />,
        state: "settings.list-social-link",
        sidebarProps: {
          displayText: "List Social Link",
        },
      },
      {
        path: "/settings/terms-image",
        element: <TermsImg />,
        state: "settings.terms-image",
        sidebarProps: {
          displayText: "Terms & Conditions Image",
        },
      },
      {
        path: "/settings/privacy-policy-image",
        element: <PrivacyPolicyImg />,
        state: "settings.privacy-policy-image",
        sidebarProps: {
          displayText: "Privacy Policy Image",
        },
      },
      // {
      //   path: "/settings/about-us-image",
      //   element: <AboutUsImg />,
      //   state: "settings.about-us-image",
      //   sidebarProps: {
      //     displayText: "About Us Image",
      //   },
      // },
      {
        path: "/settings/contact-us-image",
        element: <ContactUsImg />,
        state: "settings.contact-us-image",
        sidebarProps: {
          displayText: "Contact Us Image",
        },
      },
      {
        path: "/settings/team-banner",
        element: <TeamBannerImg />,
        state: "settings.team-banner",
        sidebarProps: {
          displayText: "Team Banner",
        },
      },
      {
        path: "/settings/team-owners",
        element: <TeamOwnersImg />,
        state: "settings.team-owners",
        sidebarProps: {
          displayText: "Team Owners Image",
        },
      },
      {
        path: "/settings/faq-image",
        element: <FaqImg />,
        state: "settings.faq-image",
        sidebarProps: {
          displayText: "FAQ Image",
        },
      },
      {
        path: "/settings/dealer-brand-logo",
        element: <DealerBrandLogo />,
        state: "settings.dealer-brand-logo",
        sidebarProps: {
          displayText: "Dealer Brand LOGO",
        },
      },
    ],
  },

  // Privacy Policy
  {
    path: "/privacy-policy",
    element: <PageLayout />,
    state: "privacy-policy",
    sidebarProps: {
      displayText: "All Page",
      icon: <NewspaperIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "privacy-policy.index",
      },
      {
        path: "/privacy-policy/add-products-privacy-policy",
        element: <AddProductPrivacyPolicyPage />,
        state: "privacy-policy.add-products-privacy-policy",
        sidebarProps: {
          displayText: "Add Products Privacy Policy",
        },
      },
      {
        path: "/privacy-policy/list-products-privacy-policy",
        element: <ListProductPrivacyPolicyPage />,
        state: "privacy-policy.list-products-privacy-policy",
        sidebarProps: {
          displayText: "List Products Privacy Policy",
        },
      },

      {
        path: "/privacy-policy/add-faq",
        element: <AddFaqPage />,
        state: "privacy-policy.add-faq",
        sidebarProps: {
          displayText: "Add FAQ",
        },
      },
      {
        path: "/privacy-policy/list-faq",
        element: <ListFaqPage />,
        state: "privacy-policy.list-faq",
        sidebarProps: {
          displayText: "List FAQ",
        },
      },

      {
        path: "/privacy-policy/add-privacy-policy",
        element: <AddPrivacyPolicyPage />,
        state: "privacy-policy.add-privacy-policy",
        sidebarProps: {
          displayText: "Add Privacy Policy",
        },
      },
      {
        path: "/privacy-policy/list-privacy-policy",
        element: <ListPrivacyPolicyPage />,
        state: "privacy-policy.list-privacy-policy",
        sidebarProps: {
          displayText: "List Privacy Policy",
        },
      },

      {
        path: "/privacy-policy/add-about-us",
        element: <AddAboutUsPage />,
        state: "privacy-policy.add-about-us",
        sidebarProps: {
          displayText: "Add About Us",
        },
      },
      {
        path: "/privacy-policy/list-about-us",
        element: <ListAboutUsPage />,
        state: "privacy-policy.list-about-us",
        sidebarProps: {
          displayText: "List About Us",
        },
      },

      {
        path: "/privacy-policy/add-terms",
        element: <AddTermsPage />,
        state: "privacy-policy.add-terms",
        sidebarProps: {
          displayText: "Add Terms & Conditions",
        },
      },
      {
        path: "/privacy-policy/list-terms",
        element: <ListTermsPage />,
        state: "privacy-policy.list-terms",
        sidebarProps: {
          displayText: "List Terms & Conditions",
        },
      },

      {
        path: "/privacy-policy/add-team",
        element: <AddTeamPage />,
        state: "privacy-policy.add-team",
        sidebarProps: {
          displayText: "Add Team",
        },
      },
      {
        path: "/privacy-policy/list-team",
        element: <ListTeamPage />,
        state: "privacy-policy.list-team",
        sidebarProps: {
          displayText: "List Team",
        },
      },

      {
        path: "/privacy-policy/add-contact-us",
        element: <AddContactUsPage />,
        state: "privacy-policy.add-contact-us",
        sidebarProps: {
          displayText: "Add Contact Us",
        },
      },
      {
        path: "/privacy-policy/list-contact-us",
        element: <ListContactUsPage />,
        state: "privacy-policy.list-contact-us",
        sidebarProps: {
          displayText: "List Contact Us",
        },
      },
    ],
  },
];

export default appRoutes;
