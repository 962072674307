import React, { Suspense } from "react";
import LazyLoader from "../../../components/common/loader/lazyLoader";
const ListTeam = React.lazy(() =>
  import("../../../components/privacyPolicy/team/listTeam")
);

const ListTeamPage = () => {
  return (
    <>
      <Suspense fallback={<LazyLoader />}>
        <ListTeam />
      </Suspense>
    </>
  );
};

export default ListTeamPage;
