import React, { Suspense } from "react";
import LazyLoader from "../../../components/common/loader/lazyLoader";
const ListTerms = React.lazy(() =>
  import("../../../components/privacyPolicy/terms/listTerms")
);

const ListTermsPage = () => {
  return (
    <>
      <Suspense fallback={<LazyLoader />}>
        <ListTerms />
      </Suspense>
    </>
  );
};

export default ListTermsPage;
