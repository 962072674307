import axios from "axios";
import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";

import {
  getToken,
  sessionDestroy,
} from "../../utils/sessionHelper/sessionHelper";
import {
  setList,
  setDetails,
} from "../../redux/features/teamOwner/teamOwnerSlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let createSocialLinkRequest = async (data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-social-link`;
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      successToast("Social Link create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllSocialLinkRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-social-link";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.length > 0) {
      return res.data?.data;
    } else {
      errorToast("Data not found!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let detailsSocialLinkRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/get-social-link-by-id/${id}`;
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.length > 0) {
      return res.data?.data;
    } else {
      errorToast("Data not found!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let updateSocialLinkRequest = async (id, data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/update-social-link/${id}`;
    let res = await axiosInstance.post(URL, data);
    store.dispatch(hideLoader());

    if (res.data.status === "success" && res.data?.data?.modifiedCount == 1) {
      successToast("Social Link udpate success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteTeamOwnerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-social-link/" + id;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data?.data?.deletedCount > 0) {
      successToast("Team Owner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
