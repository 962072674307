import axios from "axios";
import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";

import {
  getToken,
  sessionDestroy,
} from "../../utils/sessionHelper/sessionHelper";
import {
  setList,
  setDetails,
} from "../../redux/features/teamOwner/teamOwnerSlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

// Best sales Banner
export let createBestSalesBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-best-sales`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllBestSalesBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-best-sales/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteBestSalesBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-best-sales/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());
    console.log(res.data.data);
    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// Kacha Bazar Banner
export let createKachaBazarBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-kacha-bazar`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllKachaBazarBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-kacha-bazar/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteKachaBazarBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-kacha-bazar/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());
    console.log(res.data.data);
    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// bes of electronics Banner
export let createBestOfElectronicsBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-best-of-electronics`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllBestOfElectronicsBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-best-of-electronics/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteBestOfElectronicsBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-best-of-electronics/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// contact us Banner
export let createContactUsBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-contact-us`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllContactUsBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-contact-us/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteContactUsBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-contact-us/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// Team Banner
export let createTeamBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-team`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllTeamBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-team/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteTeamBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-team/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// Faq Banner
export let createFaqBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-faq`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllFaqBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-faq/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteFaqBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-faq/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// Term Banner
export let createTermBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-term-of-conditions`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllTermBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-term-of-conditions/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteTermBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-term-of-conditions/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// Privacy Policy Banner
export let createPrivacyPolicyBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-privacy-policy`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllPrivacyPolicyBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-privacy-policy/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deletePrivacyPolicyBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-privacy-policy/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// About us Banner
export let createAboutUsBannerRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-banner-about-us`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllAboutUsBannerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-banner-about-us/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteAboutUsBannerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-banner-about-us/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

// Dealer Brand Logo
export let createDealerBrandLogoRequest = async (data) => {
  let reqBody = {
    images: data,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/add-dealer-brand-logo`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Banner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllDealerBrandLogoRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/get-all-dealer-brand-logo/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      return res.data?.data?.[0]?.rows;
    } else {
      errorToast("Data not found!");
      return [];
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteDealerBrandLogoRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-dealer-brand-logo/" + id;
    let res = await axiosInstance.post(URL);
    store.dispatch(hideLoader());

    if (res.data?.data?.deletedCount > 0) {
      successToast("Banner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
