import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { errorToast } from "../../utils/notificationAlert/notificationAlert";

import { IsEmpty } from "../../utils/formValidation/formValidation";
import {
  createShippingCostAndOtherCostRequest,
  detailsShippingCostAndOtherCostRequest,
  updateShippingCostAndOtherCostRequest,
} from "../../APIRequest/shippingCostAndOtherCost/shippingCostAndOtherCostApi";
import { useNavigate } from "react-router-dom";

const ShippingAndOtherCost = () => {
  const navigate = useNavigate();
  let [id, setId] = useState("0");
  let [shippingCostValue, setShippingCostValue] = useState({
    shippingCostThakurgaonSadar: "",
    otherCostThakurgaonSadar: "",
    shippingCostThakurgaon: "",
    otherCostThakurgaon: "",
    shippingCostDhaka: "",
    otherCostDhaka: "",
    shippingCost: "",
    otherCost: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!IsEmpty(shippingCostValue.shippingCostThakurgaonSadar)) {
      errorToast("Thakurgaon Sadar Shipping Cost is required");
    } else if (!IsEmpty(shippingCostValue.otherCostThakurgaonSadar)) {
      errorToast("Thakurgaon Sadar Other Cost is required");
    } else if (!IsEmpty(shippingCostValue.shippingCostThakurgaon)) {
      errorToast("Thakurgaon Shipping Cost is required");
    } else if (!IsEmpty(shippingCostValue.otherCostThakurgaon)) {
      errorToast("Thakurgaon Other Cost is required");
    } else if (!IsEmpty(shippingCostValue.shippingCostDhaka)) {
      errorToast("Dhaka Shipping Cost is required");
    } else if (!IsEmpty(shippingCostValue.otherCostDhaka)) {
      errorToast("Dhaka Other Cost is required");
    } else if (!IsEmpty(shippingCostValue.shippingCost)) {
      errorToast("Shipping  Cost is required");
    } else if (!IsEmpty(shippingCostValue.otherCost)) {
      errorToast("Other Cost is required");
    } else {
      if (id == 0 || id == undefined) {
        let result = await createShippingCostAndOtherCostRequest(
          shippingCostValue
        );
        if (result) {
          navigate("/settings/list-shipping-and-other-cost");
        }
      } else {
        let result = await updateShippingCostAndOtherCostRequest(
          id,
          shippingCostValue
        );
        if (result) {
          navigate("/settings/list-shipping-and-other-cost");
        }
      }
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setId(params.get("id"));
    (async () => {
      let data = await detailsShippingCostAndOtherCostRequest(id);
      setShippingCostValue({
        shippingCostThakurgaonSadar: data[0]?.shippingCostThakurgaonSadar,
        otherCostThakurgaonSadar: data[0]?.otherCostThakurgaonSadar,
        shippingCostThakurgaon: data[0]?.shippingCostThakurgaon,
        otherCostThakurgaon: data[0]?.otherCostThakurgaon,
        shippingCostDhaka: data[0]?.shippingCostDhaka,
        otherCostDhaka: data[0]?.otherCostDhaka,
        shippingCost: data[0]?.shippingCost,
        otherCost: data[0]?.otherCost,
      });
    })();
  }, [id]);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Add Shipping & Other Cost
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="shippingCost"
                variant="outlined"
                type="text"
                label="Thakurgaon Sadar Shipping Cost"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    shippingCostThakurgaonSadar: e.target.value,
                  })
                }
                value={shippingCostValue.shippingCostThakurgaonSadar}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="otherCost"
                variant="outlined"
                type="text"
                label="Thakurgaon Sadar Other Cost"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    otherCostThakurgaonSadar: e.target.value,
                  })
                }
                value={shippingCostValue.otherCostThakurgaonSadar}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="shippingCost"
                variant="outlined"
                type="text"
                label="Thakurgaon Shipping Cost"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    shippingCostThakurgaon: e.target.value,
                  })
                }
                value={shippingCostValue.shippingCostThakurgaon}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="otherCost"
                variant="outlined"
                type="text"
                label="Thakurgaon Other Cost"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    otherCostThakurgaon: e.target.value,
                  })
                }
                value={shippingCostValue.otherCostThakurgaon}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="shippingCost"
                variant="outlined"
                type="text"
                label="Dhaka Shipping Cost"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    shippingCostDhaka: e.target.value,
                  })
                }
                value={shippingCostValue.shippingCostDhaka}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="otherCost"
                variant="outlined"
                type="text"
                label="Dhaka Other Cost"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    otherCostDhaka: e.target.value,
                  })
                }
                value={shippingCostValue.otherCostDhaka}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="shippingCost"
                variant="outlined"
                type="text"
                label="Shipping Cost (BD)"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    shippingCost: e.target.value,
                  })
                }
                value={shippingCostValue.shippingCost}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="otherCost"
                variant="outlined"
                type="text"
                label="Other Cost (BD)"
                onChange={(e) =>
                  setShippingCostValue({
                    ...shippingCostValue,
                    otherCost: e.target.value,
                  })
                }
                value={shippingCostValue.otherCost}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Save Change
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ShippingAndOtherCost;
