import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productsPrivacyPolicylist: [],
  faqPrivacyPolicylist: [],
  privacyPolicyList: [],
  aboutUsPrivacyPolicyList: [],
  termsAndConditionsList: [],
  teamList: [],
  contactUsList: [],
};

const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {
    setProductsPrivacyPolicylist(state, actions) {
      state.productsPrivacyPolicylist = actions.payload;
    },
    setFaqPrivacyPolicylist(state, actions) {
      state.faqPrivacyPolicylist = actions.payload;
    },
    setPrivacyPolicylist(state, actions) {
      state.privacyPolicyList = actions.payload;
    },
    setAboutUsPrivacyPolicylist(state, actions) {
      state.aboutUsPrivacyPolicyList = actions.payload;
    },
    setTermsAndConditionsList(state, actions) {
      state.termsAndConditionsList = actions.payload;
    },
    setTeamList(state, actions) {
      state.teamList = actions.payload;
    },
    setContactUsList(state, actions) {
      state.contactUsList = actions.payload;
    },
  },
});

export const {
  setProductsPrivacyPolicylist,
  setFaqPrivacyPolicylist,
  setPrivacyPolicylist,
  setAboutUsPrivacyPolicylist,
  setTermsAndConditionsList,
  setTeamList,
  setContactUsList,
} = privacyPolicySlice.actions;

export default privacyPolicySlice.reducer;
