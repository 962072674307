import React from "react";
import { Suspense } from "react";
import LazyLoader from "../../../components/common/loader/lazyLoader";
const AddTerms = React.lazy(() =>
  import("../../../components/privacyPolicy/terms/addTerms")
);

const AddTermsPage = () => {
  return (
    <>
      <Suspense fallback={<LazyLoader />}>
        <AddTerms />
      </Suspense>
    </>
  );
};

export default AddTermsPage;
