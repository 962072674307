import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  total: 0,
  details: [],
  dropdownCategoryList: [],
  dropdownSubCategoryList: [],
  dropdownBrandList: [],
  formValue: {
    productName: "",
    remark: "Regular",
    shortDescription: "",
    description: "",
    category: "",
    subCategory: "",
    brand: "",
    weight: "",
    size: [],
    color: "",
    price: "",
    finalPrice: "",
    discount: "",
    quantity: "",
    sku: "",
    country: "",
    madeIn: "",
    skinType: "",
  },
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setList(state, actions) {
      state.list = actions.payload;
    },
    setTotal(state, actions) {
      state.total = actions.payload;
    },
    deleteListItem(state, actions) {
      state.list = state.list.filter((item) => item._id !== actions.payload);
    },
    setDetails(state, actions) {
      state.details = actions.payload;
    },
    deleteImg(state, actions) {
      state.details[0].img = state.details[0].img.filter(
        (item) => item.public_id !== actions.payload
      );
    },
    resetDetails(state) {
      state.details = [];
    },
    setDropdownCategoryList(state, action) {
      state.dropdownCategoryList = action.payload;
    },
    setDropdownSubCategoryList(state, action) {
      state.dropdownSubCategoryList = action.payload;
    },
    setDropdownBrandList(state, action) {
      state.dropdownBrandList = action.payload;
    },
    setFormValue(state, action) {
      state.formValue[`${action.payload.Name}`] = action.payload.Value;
    },
    resetFormValue(state) {
      Object.keys(state.formValue).forEach(
        (item) => (state.formValue[item] = "")
      );
    },
  },
});

export const {
  setList,
  setTotal,
  deleteListItem,
  setDetails,
  deleteImg,
  resetDetails,
  resetFormValue,
  setFormValue,
  setDropdownBrandList,
  setDropdownCategoryList,
  setDropdownSubCategoryList,
} = productSlice.actions;

export default productSlice.reducer;
