import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { getBase64, IsEmpty } from "../../utils/formValidation/formValidation";
import { errorToast } from "../../utils/notificationAlert/notificationAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { useSelector } from "react-redux";
import { deleteItem } from "../../utils/notificationAlert/swalAlert";

import {
  createTeamOwnerRequest,
  deleteTeamOwnerRequest,
  detailsTeamOwnerRequest,
  getAllTeamOwnerRequest,
  updateTeamOwnerRequest,
} from "../../APIRequest/teamOwner/teamOwnerApi";

const TeamOwnersImg = () => {
  const gridRef = useRef(null);
  let mode = useSelector((state) => state.settings.mode);
  let { list, details } = useSelector((state) => state.teamOwner);
  let [id, setId] = useState("0");
  let [showImgView, setShowImgView] = useState("none");
  let imgView,
    imgRef = useRef();

  let [teamFormValue, setTeamFormValue] = useState({
    name: "",
    position: "",
    description: "",
    descriptionTwo: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    whatsapp: "",
  });

  const convertImgToBase64 = () => {
    let img = imgRef.files[0];

    getBase64(img).then((base64) => {
      setShowImgView("block");
      imgView.src = base64;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let images = imgRef.files[0];

    if (!details?.length > 0 && images === undefined) {
      errorToast("Images is required");
    } else if (images !== undefined && !images.type.includes("image")) {
      errorToast("Only support images");
    } else if (!teamFormValue.name) {
      errorToast("Name is required");
    } else if (!teamFormValue.position) {
      errorToast("Position is required");
    } else if (!teamFormValue.description) {
      errorToast("Description is required");
    } else {
      let data = {
        images,
        ...teamFormValue,
      };
      if (details?.length > 0) {
        let result = await updateTeamOwnerRequest(details[0]?._id, data);
        if (result) {
          window.location.reload();
        }
      } else {
        let result = await createTeamOwnerRequest(data);
        if (result) {
          window.location.reload();
        }
      }
    }
  };

  const deleteImg = async (id) => {
    let confirm = await deleteItem();
    if (confirm.isConfirmed) {
      let deleteImgResult = await deleteTeamOwnerRequest(id);
      if (deleteImgResult) {
        window.location.reload();
      }
    }
  };
  const handleDetailsTeamOwner = async (id) => {
    detailsTeamOwnerRequest(id);
  };

  useEffect(() => {
    if (details.length > 0) {
      const element = gridRef.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setTeamFormValue({
        name: details[0].name,
        position: details[0].position,
        description: details[0].description,
        descriptionTwo: details[0].descriptionTwo,
        facebook: details[0].facebook,
        twitter: details[0].twitter,
        linkedin: details[0].linkedin,
        whatsapp: details[0].whatsapp,
      });
    }
  }, [details]);

  useEffect(() => {
    getAllTeamOwnerRequest();
  }, []);
  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Add Team Owners Images{" "}
          <Typography variant="caption">
            ( image must be 300px x 300px)
          </Typography>
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          <Box
            sx={{
              display: list?.length > 0 ? "block" : "none",
              pb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: 8,
                columnGap: 0,
              }}
            >
              {list?.map((item, index) => (
                <Box sx={{ display: "flex" }} key={index}>
                  <Box>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={item?.img?.slice(-1)[0]?.secure_url}
                      alt=""
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "30px",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "10px",
                      }}
                    >
                      <p>{item?.name}</p>
                      <p>{item?.position}</p>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <DeleteIcon
                      onClick={() => deleteImg(item._id)}
                      style={{
                        color: mode === "dark" ? "#512DA8" : "#fff",
                        cursor: "pointer",
                      }}
                    />

                    <EditIcon
                      onClick={() => handleDetailsTeamOwner(item._id)}
                      style={{
                        color: mode === "dark" ? "#512DA8" : "#fff",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        {/* for add   img  */}
        <Box sx={{ display: showImgView, paddingTop: "50px" }}>
          <img
            style={{ width: "300px", height: "200px" }}
            src=""
            alt=""
            ref={(input) => (imgView = input)}
          />
        </Box>

        <Box
          sx={{ paddingTop: "50px" }}
          component="form"
          onSubmit={handleSubmit}
          accept="image/*"
        >
          <Grid container spacing={3} sx={{ mb: 2 }} ref={gridRef}>
            <Grid item xs={12} md={4} id="file">
              <MyTextField
                fullWidth
                id="file"
                variant="outlined"
                type="file"
                name="images"
                onChange={convertImgToBase64}
                inputRef={(input) => (imgRef = input)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MyTextField
                fullWidth
                id="name"
                variant="outlined"
                type="text"
                value={teamFormValue.name}
                onChange={(e) => {
                  setTeamFormValue({
                    ...teamFormValue,
                    name: e.target.value,
                  });
                }}
                label="Name *"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MyTextField
                fullWidth
                id="position"
                variant="outlined"
                type="text"
                value={teamFormValue.position}
                onChange={(e) => {
                  setTeamFormValue({
                    ...teamFormValue,
                    position: e.target.value,
                  });
                }}
                label="position *"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MyTextField
                fullWidth
                id="description"
                variant="outlined"
                type="text"
                value={teamFormValue.description}
                onChange={(e) => {
                  setTeamFormValue({
                    ...teamFormValue,
                    description: e.target.value,
                  });
                }}
                multiline
                minRows={2}
                size="small"
                label="description *"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <MyTextField
                fullWidth
                id="Facebook"
                variant="outlined"
                type="text"
                value={teamFormValue.facebook}
                onChange={(e) => {
                  setTeamFormValue({
                    ...teamFormValue,
                    facebook: e.target.value,
                  });
                }}
                label="Facebook Link"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MyTextField
                fullWidth
                id="X"
                variant="outlined"
                type="text"
                value={teamFormValue.twitter}
                onChange={(e) => {
                  setTeamFormValue({
                    ...teamFormValue,
                    twitter: e.target.value,
                  });
                }}
                label="X Link"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MyTextField
                fullWidth
                id="Linkedin"
                variant="outlined"
                type="text"
                value={teamFormValue.linkedin}
                onChange={(e) => {
                  setTeamFormValue({
                    ...teamFormValue,
                    linkedin: e.target.value,
                  });
                }}
                label="Linkedin Link"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MyTextField
                fullWidth
                id="Whatsapp"
                variant="outlined"
                type="text"
                value={teamFormValue.whatsapp}
                onChange={(e) => {
                  setTeamFormValue({
                    ...teamFormValue,
                    whatsapp: e.target.value,
                  });
                }}
                label="Whatsapp Link"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              {details?.length > 0 ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default TeamOwnersImg;
