import { configureStore } from "@reduxjs/toolkit";
import categorySlice from "./features/category/categorySlice";
import brandSlice from "./features/brand/brandSlice";
import subCategorySlice from "./features/subCategory/subCategorySlice";
import settingsSlice from "./features/settings/setttingsSlice";
import productSlice from "./features/product/productSlice";
import couponSlice from "./features/coupon/couponSlice";
import summarySlice from "./features/summary/summarySlice";
import reportSlice from "./features/report/reportSlice";
import userAndAdminSlice from "./features/userAndAdmin/userAndAdminSlice";
import orderSlice from "./features/orders/ordersSlice";
import webSetttingsSlice from "./features/webSettings/webSetttingsSlice";
import privacyPolicySlice from "./features/privacyPolicy/privacyPolicySlice";
import mainSliderSlice from "./features/mainSlider/mainSliderSlice";
import teamOwnerSlice from "./features/teamOwner/teamOwnerSlice";

export default configureStore({
  reducer: {
    settings: settingsSlice,
    categoryList: categorySlice,
    brandList: brandSlice,
    subCategoryList: subCategorySlice,
    productList: productSlice,
    couponList: couponSlice,
    summary: summarySlice,
    report: reportSlice,
    userAndAdmin: userAndAdminSlice,
    orderList: orderSlice,
    webSettings: webSetttingsSlice,
    privacyPolicy: privacyPolicySlice,
    mainSlider: mainSliderSlice,
    teamOwner: teamOwnerSlice,
  },
});
