import { colors } from "@mui/material";
import { deepPurple, grey } from "@mui/material/colors";

const colorConfigs = {
  sidebar: {
    bg: "#233044",
    color: "#eeeeee",
    hoverBg: "#FF5F84",
    activeBg: "#FF5F84",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["100"],
};

export default colorConfigs;
