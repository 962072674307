import React from "react";
import AddProduct from "../../components/product/addProduct";

const AddProductPage = () => {
  return (
    <div>
      <AddProduct />
    </div>
  );
};

export default AddProductPage;
