import axios from "axios";
import axiosInstance from "../../utils/axios/axios";
import baseUrl from "../../utils/baseUrl/baseUrl";
import {
  successToast,
  errorToast,
} from "../../utils/notificationAlert/notificationAlert";
import store from "../../redux/store";
import {
  hideLoader,
  showLoader,
} from "../../redux/features/settings/setttingsSlice";

import {
  getToken,
  sessionDestroy,
} from "../../utils/sessionHelper/sessionHelper";
import {
  setList,
  setDetails,
} from "../../redux/features/teamOwner/teamOwnerSlice";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};

export let createTeamOwnerRequest = async (data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/create-team-owner`;
    let res = await axios.post(URL, data, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.length > 0 ||
        res.data?.infoResult?.img?.length > 0)
    ) {
      successToast("Team Owner create success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let getAllTeamOwnerRequest = async () => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/list-team-owners/1/100/0";
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    console.log(res.data?.data?.[0]?.rows);
    if (res.data?.data?.[0]?.total?.[0]?.count > 0) {
      store.dispatch(setList(res.data?.data?.[0]?.rows));
      return true;
    } else {
      errorToast("Data not found!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let detailsTeamOwnerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/get-team-owners-by-id/${id}`;
    let res = await axiosInstance.get(URL, AxiosHeader);
    store.dispatch(hideLoader());
    console.log(res.data?.data?.[0]?.rows);
    if (res.data?.data?.length > 0) {
      store.dispatch(setDetails(res.data?.data));
      return true;
    } else {
      errorToast("Data not found!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteTeamOwnerRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/delete-team-owner/" + id;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data?.data?.deletedCount > 0) {
      successToast("Team Owner delete success!");
      return true;
    } else {
      errorToast("Fail Please try again!");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};

export let updateTeamOwnerRequest = async (id, data) => {
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + `/update-team-owner/${id}`;
    let res = await axios.post(URL, data, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      (res.data?.fileResult?.modifiedCount > 0 ||
        res.data?.infoResult?.modifiedCount > 0)
    ) {
      successToast("Team Owner udpate success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    if (e?.response?.status == 401) {
      sessionDestroy();
    }
    errorToast("Something Went Wrong");
    return false;
  }
};
