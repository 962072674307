import React, { Suspense } from "react";
import LazyLoader from "../../../components/common/loader/lazyLoader";
const ListAboutUs = React.lazy(() =>
  import("../../../components/privacyPolicy/aboutUs/listAboutUs")
);

const ListAboutUsPage = () => {
  return (
    <>
      <Suspense fallback={<LazyLoader />}>
        <ListAboutUs />
      </Suspense>
    </>
  );
};

export default ListAboutUsPage;
