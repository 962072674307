import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  allWebSettings: [],

  shippingCostThakurgaonSadarValue: "",
  otherCostThakurgaonSadarValue: "",

  shippingCostThakurgaonValue: "",
  otherCostThakurgaonValue: "",

  shippingCostDhakaValue: "",
  otherCostDhakaValue: "",

  shippingCostValue: "",
  otherCostValue: "",

  socialLink: {
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    whatsapp: "",
    wechat: "",
    tiktok: "",
    linkedin: "",
  },
};

const webSettingsSlice = createSlice({
  name: "websettings",
  initialState,
  reducers: {
    setAllWebSettings(state, actions) {
      state.allWebSettings = actions.payload;

      state.shippingCostThakurgaonSadarValue =
        state.allWebSettings[0]?.shippingCostThakurgaonSadar;
      state.otherCostThakurgaonSadarValue =
        state.allWebSettings[0]?.otherCostThakurgaonSadar;

      state.shippingCostThakurgaonValue =
        state.allWebSettings[0]?.shippingCostThakurgaon;
      state.otherCostThakurgaonValue =
        state.allWebSettings[0]?.otherCostThakurgaon;

      state.shippingCostDhakaValue = state.allWebSettings[0]?.shippingCostDhaka;
      state.otherCostDhakaValue = state.allWebSettings[0]?.otherCostDhaka;

      state.shippingCostValue = state.allWebSettings[0]?.shippingCost;
      state.otherCostValue = state.allWebSettings[0]?.otherCost;

      state.socialLink = {
        facebook: state.allWebSettings[0]?.socialLink?.facebook,
        twitter: state.allWebSettings[0]?.socialLink?.twitter,
        instagram: state.allWebSettings[0]?.socialLink?.instagram,
        youtube: state.allWebSettings[0]?.socialLink?.youtube,
        whatsapp: state.allWebSettings[0]?.socialLink?.whatsapp,
        wechat: state.allWebSettings[0]?.socialLink?.wechat,
        tiktok: state.allWebSettings[0]?.socialLink?.tiktok,
        linkedin: state.allWebSettings[0]?.socialLink?.linkedin,
      };
    },
    setShippingCostThakurgaonSadarValue(state, actions) {
      state.shippingCostThakurgaonSadarValue = actions.payload;
    },
    setOtherCostThakurgaonSadarValue(state, actions) {
      state.otherCostThakurgaonSadarValue = actions.payload;
    },

    setShippingCostThakurgaonValue(state, actions) {
      state.shippingCostThakurgaonValue = actions.payload;
    },
    setOtherCostThakurgaonValue(state, actions) {
      state.otherCostThakurgaonValue = actions.payload;
    },

    setShippingCostDhakaValue(state, actions) {
      state.shippingCostDhakaValue = actions.payload;
    },
    setOtherCostDhakaValue(state, actions) {
      state.otherCostDhakaValue = actions.payload;
    },

    setShippingCostValue(state, actions) {
      state.shippingCostValue = actions.payload;
    },
    setOtherCostValue(state, actions) {
      state.otherCostValue = actions.payload;
    },

    setSocialLink(state, actions) {
      state.socialLink[actions.payload.Name] = actions.payload.Value;
    },
  },
});

export const {
  setAllWebSettings,
  setOtherCostDhakaValue,
  setOtherCostThakurgaonSadarValue,
  setOtherCostThakurgaonValue,
  setOtherCostValue,
  setShippingCostDhakaValue,
  setShippingCostThakurgaonSadarValue,
  setShippingCostThakurgaonValue,
  setSocialLink,
  setShippingCostValue,
} = webSettingsSlice.actions;

export default webSettingsSlice.reducer;
