import axios from "axios";
import baseUrl from "../utils/baseUrl/baseUrl";
import axiosInstance from "../utils/axios/axios";
import {
  successToast,
  errorToast,
} from "../utils/notificationAlert/notificationAlert";
import store from "../redux/store";
import {
  hideLoader,
  showLoader,
} from "../redux/features/settings/setttingsSlice";

import {
  setDetails,
  setList,
  setTotal,
} from "../redux/features/brand/brandSlice";
import { getToken, sessionDestroy } from "../utils/sessionHelper/sessionHelper";

const AxiosHeader = {
  withCredentials: true,
  headers: { token: getToken(), "Content-Type": "multipart/form-data" },
};
export let createBrandRequest = async (images, name) => {
  let postBody = {
    images,
    name,
  };
  try {
    store.dispatch(showLoader());
    let URL = baseUrl + "/create-brand";
    let res = await axios.post(URL, postBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (res.data.status === "success" && res.data.fileResult.length > 0) {
      successToast("Brand create success");
      return true;
    } else if (res.data.data.keyPattern.name === 1) {
      errorToast(`"${postBody.name}" already exits`);
      return false;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let listBrandRequest = async (pageNo, perPage, searchKeyword) => {
  try {
    store.dispatch(showLoader());
    let URL = `/list-brand/${pageNo}/${perPage}/${searchKeyword}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      if (res.data.data[0].rows.length > 0) {
        store.dispatch(setList(res.data.data[0].rows));
        store.dispatch(setTotal(res.data.data[0].total[0].count));
        return true;
      } else {
        store.dispatch(setList([]));
        store.dispatch(setTotal([]));
        errorToast("Data not found");
        return false;
      }
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteBrandImg = async (id, public_id) => {
  let reqBody = { imgId: public_id };
  try {
    store.dispatch(showLoader());
    let URL = `/delete-brand-img/${id}`;
    let res = await axiosInstance.post(URL, reqBody);
    store.dispatch(hideLoader());
    if (res.data.status === "success" && res.data.fileResult.result === "ok") {
      successToast("Image delete success");
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};

export let deleteBrandRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = `/delete-brand/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());

    if (res.data.status === "success") {
      successToast("Brand delete success");
      return true;
    } else if (res.data.status === "associate") {
      errorToast(res.data.data);
      return false;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let detailsBrandRequest = async (id) => {
  try {
    store.dispatch(showLoader());
    let URL = `/brand-details/${id}`;
    let res = await axiosInstance.get(URL);
    store.dispatch(hideLoader());
    if (res.data.status === "success") {
      store.dispatch(setDetails(res.data.data));
      return true;
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
export let updateBrandRequest = async (id, images, brand) => {
  let reqBody = { images: images, name: brand };
  try {
    store.dispatch(showLoader());
    let URL = `${baseUrl}/update-brand/${id}`;
    let res = await axios.post(URL, reqBody, AxiosHeader);
    store.dispatch(hideLoader());

    if (
      res.data.status === "success" &&
      res.data.infoResult.modifiedCount === 1
    ) {
      successToast("Brand update success");
      return true;
    } else if (res.data.status === "unauthorized") {
      sessionDestroy();
    } else {
      errorToast("Request fail. Please try again");
      return false;
    }
  } catch (e) {
    store.dispatch(hideLoader());
    errorToast("Something Went Wrong");
    return false;
  }
};
