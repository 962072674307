import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import DOMPurify from "dompurify";
import { Avatar, Button, Typography } from "@mui/material";
import { getBase64, IsEmpty } from "../../utils/formValidation/formValidation";
import { errorToast } from "../../utils/notificationAlert/notificationAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { deleteItem } from "../../utils/notificationAlert/swalAlert";
import { useNavigate } from "react-router-dom";
import store from "../../redux/store";
import ReactQuill from "react-quill";
import {
  deleteImg,
  resetDetails,
  resetFormValue,
} from "../../redux/features/product/productSlice";
import { MyFormControl } from "../common/myComponents/myFormControl";
import { setFormValue } from "../../redux/features/product/productSlice";
import {
  createProductRequest,
  deleteProductImg,
  detailsProductRequest,
  dropdownBrandList,
  dropdownCategoryList,
  dropdownSubCategoryList,
  updateProduct,
} from "../../APIRequest/productApi";

const AddProduct = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const convertImgToBase64 = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      console.log("No files selected.");
      return;
    }

    const files = e.target.files;
    const selectedImagesArray = [...selectedImages];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if the file is indeed a blob or a file
      if (!(file instanceof Blob)) {
        console.error("The item is not a Blob or File", file);
        continue; // Skip to the next iteration of loop
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        selectedImagesArray.push({ file, base64: event.target.result });
        setSelectedImages([...selectedImagesArray]);
      };

      reader.onerror = (error) => {
        console.error("FileReader error: ", error);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImageInForntend = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  let modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: [] }],
    ],
  };

  const navigate = useNavigate();
  let [id, setId] = useState("0");
  let [convertData, setConvertData] = useState([]);
  let mode = useSelector((state) => state.settings.mode);
  let {
    productName,
    remark,
    shortDescription,
    description,
    category,
    subCategory,
    brand,
    weight,
    size,
    color,
    price,
    discount,
    quantity,
    sku,
    country,
    madeIn,
    skinType,
  } = useSelector((state) => state.productList.formValue);
  const [value, setValue] = useState(description || "");
  let productDetails = useSelector((state) => state.productList.details);
  let dropdownCategory = useSelector(
    (state) => state.productList.dropdownCategoryList
  );
  let dropdownSubCategory = useSelector(
    (state) => state.productList.dropdownSubCategoryList
  );
  let dropdownBrand = useSelector(
    (state) => state.productList.dropdownBrandList
  );
  let [unitPriceAfterDiscount, setUnitPriceAfterDiscount] = useState(0);
  let [saveAmountAfterDiscount, setSaveAmountAfterDiscount] = useState(0);

  let imgRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    let imgs = imgRef.files;
    selectedImages.map((img) => {
      formData.append("images", img?.file);
    });
    // for (var key of Object.keys(imgs)) {
    //   formData.append("images", imgs[key]);
    // }

    formData.append("name", productName);
    const sanitizedValue = DOMPurify.sanitize(value);
    formData.append("description", sanitizedValue);
    formData.append("categoryId", category);
    formData.append("subCategoryId", subCategory);
    formData.append("brandId", brand);
    formData.append("weight", weight);
    if (size?.length > 0) {
      size.forEach((value, index) => {
        formData.append(`size[${index}]`, value);
      });
    }
    formData.append("color", color);
    formData.append("price", price);
    formData.append("discount", discount);
    formData.append("quantity", quantity);
    formData.append("country", country);
    formData.append("sku", sku);
    formData.append("skinType", skinType);
    formData.append("shortDescription", shortDescription);
    formData.append("madeIn", madeIn);
    formData.append("remark", remark);

    if (imgs.length === 0 && id == null && id == "0") {
      errorToast("Images is required");
    } else if (imgs.length > 0 && !imgs[0]?.type.includes("image/")) {
      errorToast(`${imgs[0].name} is not an image file.`);
    } else if (imgs.length > 1 && !imgs[1].type.includes("image/")) {
      errorToast(`${imgs[1].name} is not an image file.`);
    } else if (imgs.length > 2 && !imgs[2].type.includes("image/")) {
      errorToast(`${imgs[2].name} is not an image file.`);
    } else if (imgs.length > 3 && !imgs[3].type.includes("image/")) {
      errorToast(`${imgs[3].name} is not an image file.`);
    } else if (imgs.length > 4 && !imgs[4].type.includes("image/")) {
      errorToast(`${imgs[4].name} is not an image file.`);
    } else if (imgs.length > 5 && !imgs[5].type.includes("image/")) {
      errorToast(`${imgs[5].name} is not an image file.`);
    } else if (imgs.length > 6 && !imgs[6].type.includes("image/")) {
      errorToast(`${imgs[6].name} is not an image file.`);
    } else if (imgs.length > 7 && !imgs[7].type.includes("image/")) {
      errorToast(`${imgs[7].name} is not an image file.`);
    } else if (imgs.length > 8 && !imgs[8].type.includes("image/")) {
      errorToast(`${imgs[8].name} is not an image file.`);
    } else if (imgs.length > 9 && !imgs[9].type.includes("image/")) {
      errorToast(`${imgs[9].name} is not an image file.`);
    } else if (imgs.length > 10 && !imgs[10].type.includes("image/")) {
      errorToast(`${imgs[10].name} is not an image file.`);
    } else if (formData.getAll("images").length > 10) {
      errorToast("Images upload maximum 10");
    } else if (!IsEmpty(productName)) {
      errorToast("Product name is required");
    } else if (!IsEmpty(remark)) {
      errorToast("Remark is required");
    } else if (!IsEmpty(shortDescription)) {
      errorToast("Short description is required");
    } else if (!IsEmpty(category)) {
      errorToast("Category is required");
    } else if (!IsEmpty(subCategory)) {
      errorToast("Sub-category is required");
    } else if (!IsEmpty(brand)) {
      errorToast("Brand is required");
    } else if (!IsEmpty(price)) {
      errorToast("Price is required");
    } else if (!IsEmpty(quantity)) {
      errorToast("Quantity is required");
    } else {
      if (id !== null && id !== "0") {
        let result = await updateProduct(id, formData);
        if (result) {
          navigate("/products/list-products");
          store.dispatch(resetDetails());
          store.dispatch(resetFormValue());
        }
      } else {
        let result = await createProductRequest(formData);
        if (result) {
          navigate("/products/list-products");
          // window.location.reload();
        }
      }
    }
  };

  const handleDeleteImgInBackend = async (public_id) => {
    let confirm = await deleteItem();
    if (confirm.isConfirmed) {
      let deleteImgResult = await deleteProductImg(id, public_id);
      if (deleteImgResult) {
        store.dispatch(deleteImg(public_id));
      }
    }
  };

  useEffect(() => {
    dropdownCategoryList();
    dropdownSubCategoryList();
    dropdownBrandList();
    // details for update
    let params = new URLSearchParams(window.location.search);
    setId(params.get("id"));
    if (id !== null && id !== "0") {
      (async () => {
        await detailsProductRequest(id);
      })();
    }

    // add category field reset when user click update category but not update
    return () => {
      store.dispatch(resetDetails());
      store.dispatch(resetFormValue());
    };
  }, [id]);

  useEffect(() => {
    if (id !== null && id !== "0") {
      if (description && typeof description === "string") {
        // DOMPurify ব্যবহার করে description স্যানিটাইজ করুন
        const sanitizedDescription = DOMPurify.sanitize(description);
        setValue(sanitizedDescription);
      } else {
        setValue(""); // অথবা আপনার পছন্দমত ডিফল্ট মান
      }
    }
  }, [description, id]); // id কে ডিপেনডেন্সি হিসেবে যুক্ত করুন

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {id !== null && id !== "0" ? "Update Product" : "Add Product"}
          <span
            style={{
              fontSize: "12px",
              marginLeft: "5px",
              fontWeight: "400",
            }}
          >
            Products images must be 600px*600px And avarage images size 10mb
          </span>
        </Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          {/* for add category img  */}
          <Grid container spacing={1}>
            {selectedImages.map((image, index) => (
              <Grid key={index} item xs={4} sm={2}>
                <Box sx={{ pb: 2 }}>
                  <img
                    style={{ width: "80px", height: "80px" }}
                    src={image.base64}
                    alt={`Image ${index}`}
                  />
                  <Button
                    onClick={() => handleDeleteImageInForntend(index)}
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* for edit category img  */}
        <Box
          sx={{
            display: productDetails?.[0]?.img?.length > 0 ? "block" : "none",
            pb: 2,
          }}
        >
          <Box>
            <Grid container>
              {productDetails?.[0]?.img?.map((item, index) => (
                <Grid item xs={6} md={2} key={index}>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Avatar
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      src={item.secure_url}
                      alt=""
                    />
                    <DeleteIcon
                      onClick={() => handleDeleteImgInBackend(item.public_id)}
                      style={{
                        color: mode === "dark" ? "#512DA8" : "#fff",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box component="form" onSubmit={handleSubmit} accept="image/*">
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={4}>
              <MyTextField
                inputProps={{
                  multiple: true,
                }}
                fullWidth
                id="file"
                variant="outlined"
                type="file"
                name="images"
                onChange={convertImgToBase64}
                inputRef={(input) => (imgRef = input)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({ Name: "productName", Value: e.target.value })
                  )
                }
                value={productName}
                fullWidth
                id="productName"
                label="Product Name *"
                type="text"
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <MyFormControl fullWidth>
                <InputLabel id="remark">Remark * </InputLabel>
                <Select
                  onChange={(e) =>
                    store.dispatch(
                      setFormValue({ Name: "remark", Value: e.target.value })
                    )
                  }
                  labelId="remark"
                  id="remark"
                  label="remark"
                  value={remark}
                >
                  <MenuItem value={"New"}>New</MenuItem>
                  <MenuItem value={"Popular"}>Popular</MenuItem>
                  <MenuItem value={"Trending"}>Trending</MenuItem>
                  <MenuItem value={"Top"}>Top</MenuItem>
                  <MenuItem value={"Special"}>Special</MenuItem>
                  <MenuItem value={"Regular"}>Regular</MenuItem>
                </Select>
              </MyFormControl>
            </Grid>

            <Grid item xs={12}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "shortDescription",
                      Value: e.target.value,
                    })
                  )
                }
                value={shortDescription}
                fullWidth
                multiline
                id={Math.random()}
                label="Short Description *"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="overline"
                sx={{ border: "1px solid #ccc", display: "inline" }}
              >
                Description
              </Typography>
              <ReactQuill
                modules={modules}
                theme="snow"
                value={value}
                onChange={setValue}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <MyFormControl fullWidth>
                <InputLabel id="category">Category * </InputLabel>
                <Select
                  onChange={(e) =>
                    store.dispatch(
                      setFormValue({ Name: "category", Value: e.target.value })
                    )
                  }
                  labelId="category"
                  id="category"
                  value={category}
                  label="Category"
                >
                  {dropdownCategory?.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </MyFormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MyFormControl fullWidth>
                <InputLabel id="subcategory">Sub category *</InputLabel>
                <Select
                  onChange={(e) =>
                    store.dispatch(
                      setFormValue({
                        Name: "subCategory",
                        Value: e.target.value,
                      })
                    )
                  }
                  labelId="subcategory"
                  id="subcategory"
                  value={subCategory}
                  label="Sub category"
                >
                  {dropdownSubCategory?.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </MyFormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MyFormControl fullWidth>
                <InputLabel id="brand">Brand *</InputLabel>
                <Select
                  onChange={(e) =>
                    store.dispatch(
                      setFormValue({
                        Name: "brand",
                        Value: e.target.value,
                      })
                    )
                  }
                  labelId="brand"
                  id="brand"
                  value={brand}
                  label="Brand"
                >
                  {dropdownBrand?.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </MyFormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                fullWidth
                id="weight"
                label="Weight"
                type="text"
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "weight",
                      Value: e.target.value,
                    })
                  )
                }
                value={weight}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                fullWidth
                id="size"
                label="Size"
                type="text"
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "size",
                      Value: e.target.value.toUpperCase().split(/[ ,]+/),
                    })
                  )
                }
                value={size}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "color",
                      Value: e.target.value,
                    })
                  )
                }
                value={color}
                fullWidth
                id="color"
                label="Color"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "quantity",
                      Value: e.target.value,
                    })
                  )
                }
                value={quantity}
                fullWidth
                id="Quantity"
                label="Quantity *"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "skinType",
                      Value: e.target.value,
                    })
                  )
                }
                value={skinType}
                fullWidth
                id="Skin Type"
                label="Skin Type"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "country",
                      Value: e.target.value,
                    })
                  )
                }
                value={country}
                fullWidth
                id="country"
                label="country"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "sku",
                      Value: e.target.value,
                    })
                  )
                }
                value={sku}
                fullWidth
                id="sku"
                label="sku"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) =>
                  store.dispatch(
                    setFormValue({
                      Name: "madeIn",
                      Value: e.target.value,
                    })
                  )
                }
                value={madeIn}
                fullWidth
                id="Made in"
                label="Made in"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) => {
                  store.dispatch(
                    setFormValue({
                      Name: "price",
                      Value: e.target.value,
                    })
                  );
                }}
                onKeyUp={(e) => {
                  setUnitPriceAfterDiscount(e.target.value);
                  if (discount) {
                    setUnitPriceAfterDiscount(
                      (
                        (Number(price) * (100 - Number(discount))) /
                        100
                      ).toFixed()
                    );
                    setSaveAmountAfterDiscount(
                      (Number(price) * (Number(discount) / 100)).toFixed()
                    );
                  }
                }}
                value={price}
                fullWidth
                id="Price"
                label="Price *"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                onChange={(e) => {
                  store.dispatch(
                    setFormValue({
                      Name: "discount",
                      Value: e.target.value,
                    })
                  );
                }}
                onKeyUp={() => {
                  setUnitPriceAfterDiscount(
                    ((Number(price) * (100 - Number(discount))) / 100).toFixed()
                  );
                  setSaveAmountAfterDiscount(
                    (Number(price) * (Number(discount) / 100)).toFixed()
                  );
                }}
                value={discount}
                fullWidth
                id="discount"
                label="Discount %"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                disabled
                fullWidth
                value={unitPriceAfterDiscount}
                id="finalPrice"
                label="Final price"
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MyTextField
                disabled
                fullWidth
                value={saveAmountAfterDiscount}
                id="saveAmount"
                label="Save amount"
                type="text"
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              {id !== null && id !== "0" ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddProduct;
