import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/common/loader/lazyLoader";
const RefundCashback = lazy(() =>
  import("../../components/refundCashback/refundCashback")
);

const AddCouponPage = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <RefundCashback />
    </Suspense>
  );
};

export default AddCouponPage;
