import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MyTextField } from "../common/myComponents/myTextField";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  createSocialLinkRequest,
  detailsSocialLinkRequest,
  updateSocialLinkRequest,
} from "../../APIRequest/socialLink/socialLinkApi";
import { errorToast } from "../../utils/notificationAlert/notificationAlert";

const SocialLink = () => {
  const navigate = useNavigate();
  let [socialLink, setSocialLink] = useState({
    name: "",
    description: "",
    socialLink: "",
  });
  let [id, setId] = useState("0");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!socialLink.name) {
      errorToast("Please Enter Social Name");
    } else if (!socialLink.socialLink) {
      errorToast("Please Enter Social Link");
    } else {
      if (id == 0 || id == undefined) {
        let result = await createSocialLinkRequest(socialLink);
        if (result) {
          navigate("/settings/list-social-link");
        }
      } else {
        let result = await updateSocialLinkRequest(id, socialLink);
        if (result) {
          navigate("/settings/list-social-link");
        }
      }
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setId(params.get("id"));
    (async () => {
      let data = await detailsSocialLinkRequest(id);
      setSocialLink({
        name: data[0]?.name,
        description: data[0]?.description,
        socialLink: data[0]?.socialLink,
      });
    })();
  }, [id]);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Add Social Link
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="name"
                variant="outlined"
                type="text"
                onChange={(e) =>
                  setSocialLink({ ...socialLink, name: e.target.value })
                }
                value={socialLink.name}
                label="Social Name *"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyTextField
                fullWidth
                id="link"
                variant="outlined"
                type="text"
                value={socialLink?.socialLink}
                onChange={(e) =>
                  setSocialLink({ ...socialLink, socialLink: e.target.value })
                }
                label="Social Link *"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MyTextField
                fullWidth
                id="descriptions"
                variant="outlined"
                type="text"
                value={socialLink?.description}
                onChange={(e) =>
                  setSocialLink({ ...socialLink, description: e.target.value })
                }
                label="Description"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Save Change
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SocialLink;
